import React from 'react';
import { Box, Grid, Link, Stack, Typography, Slide, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import DisclaimerModal from '../DisclaimerModal/DisclaimerModal';
import styles from './Footer.module.css';
import Logo from '../../assets/images/Main-header-logo.svg';
import Discord from '../../assets/images/discord.svg';
import Twitter from '../../assets/images/twitter.svg';
import Opensea from '../../assets/images/opensea.png';
import Treelink from '../../assets/images/treelink.png';
import Matic from '../../assets/images/matic.png';
import Studio from '../../assets/images/Studio-navbar-logo.svg'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  }
  return (
    <Box className={styles.footerSection}>
      <Box className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={styles.leftColumn}>
            <NavLink to="/" end>
              <img src={Logo} alt='' loading='lazy' height={33} />
            </NavLink>
          </Grid>

          <Grid item xs={12} md={4} className={styles.centerColumn}>
            <Typography variant='h4' className={styles.footerHeading}>Links</Typography>
            {!isMobile ? <Stack direction='row'>
              <a href='https://mint.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Mint</a>
              <a href='https://genesiswhitepaper.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Whitepaper</a>
              <NavLink to='/faq' className={styles.footerLink}>Faq</NavLink>
              <a href='https://media.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Media</a>
              <HashLink to='/#starchart' className={styles.footerLink}>Starchart</HashLink>
              <a href='https://moonizenstudios.com/' style={{width:"120px", height:"40px", padding:"0px 20px"}} target='_blank' rel="noreferrer">
                <img className="logo" src={Studio} loading='lazy' alt='' width="90px" height="20px"/>
              </a>
            </Stack> : 
              <>
                <Stack direction='row'>
                  <a href='https://mint.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Mint</a>
                  <a href='https://genesiswhitepaper.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Whitepaper</a>
                  <NavLink to='/faq' className={styles.footerLink}>Faq</NavLink>
                </Stack>
                <Stack direction='row'>
                  <a href='https://media.moonizens.com/' className={styles.footerLink} target='_blank' rel="noreferrer">Media</a>
                  <HashLink to='/#starchart' className={styles.footerLink}>Starchart</HashLink>
                  <a href='https://moonizenstudios.com/' className={styles.footerLink} target='_blank' rel="noreferrer">
                    <img className="logo" src={Studio} loading='lazy' alt='' width="75px" height="15px"/>
                  </a>
                </Stack>
              </>
            }
            
          </Grid>

          <Grid item xs={12} md={4} className={styles.rightColumn}>
            <Link href='https://discord.gg/moonizens'
                  target='_blank'
                  rel='nofollow'
                  className={styles.footerSocialLink}>
              <img src={Discord} alt='discord' loading='lazy' width={22}/>
            </Link>
            <Link href='https://twitter.com/moonizensnft'
                  target='_blank'
                  rel='nofollow'
                  className={styles.footerSocialLink}>
              <img src={Twitter} alt='twitter' loading='lazy' width={22}/>
            </Link>

            <Link href='https://opensea.io/collection/moonizen-genesis'
                  target='_blank'
                  rel='nofollow'
                  className={styles.footerSocialLink}>
              <img src={Opensea} alt='opensea' loading='lazy' width={22}/>
            </Link>

            <Link href='https://linktr.ee/moonizens'
                  target='_blank'
                  rel='nofollow'
                  className={styles.footerSocialLink}>
              <img src={Treelink} alt='treelink' loading='lazy' width={22}/>
            </Link>

            <Link href='https://polygonscan.com/token/0xc4e051863a646395b8503fb42dd1583398114454'
                  target='_blank'
                  rel='nofollow'
                  className={styles.footerSocialLink}>
              <img src={Matic} alt='matic' loading='lazy' width={22}/>
            </Link>
          </Grid>
        </Grid>
        <Box className={styles.mainFooter}>
          <Typography align='center'>
            <a href='#' className={styles.disclaimer} onClick={() => setOpenModal(true)}>Disclaimer</a>
            <br />
            Copyright © Moonizens. All Rights Reserved
          </Typography>
        </Box>
      </Box>

      <DisclaimerModal open={openModal} transition={Transition} handleClose={handleCloseModal}/>
    </Box>
  );
};

export default Footer;