import React from 'react';
import { Box, Typography, Stack, useMediaQuery } from '@mui/material';

import styles from './Studios.module.css';
import ServiceImg from '../../assets/images/sersvice.jpg';
import Studio from '../../assets/images/Studio-title-logo.svg'

const Studios = () => {

    const isMobile = useMediaQuery('(max-width:991px)');

    return (
        <>
            <Box className={styles.pageWrapper}>
                <Box className="container">
                    <Typography align='center' className={styles.heading} variant='h1'>
                        <img src={Studio} />
                    </Typography>
                    <Box className="w-container">
                        <Box mb={5}>
                            
                                <p align={isMobile ? 'center' : ''}>
                                    Witness Moonizens Studios unparalleled creative and technical expertise through our stunning Genesis NFT collection. Partner with us to manifest your NFT project vision, joining our unwavering commitment to artistic innovation and trailblazing Web3 advancements.
                                </p>
                                <p align={isMobile ? 'center' : ''}>
                                    At Moonizen Studios, we understand the importance of human touch in the digital world. Our artists bring their unique perspectives and experiences to every project, ensuring that each piece of art we create is infused with personality and originality. We believe that custom, human-developed art is an essential element in creating engaging and memorable experiences that truly resonate with users.
                                </p>
                        </Box>

                        <Box mb={5}>
                            <Typography variant='h3' align={isMobile ? 'center' : ''} mb={3} className={styles.titleDecoration}>
                                Services
                            </Typography>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <p>
                                    -Website Artwork<br />
                                    -2D Assets<br/>
                                    -3D Assets<br />
                                    -Concept Art<br />
                                    -NFT Services<br />
                                    -Gamification Assets<br />
                                    -Lore<br />
                                    -Lore Concept<br />
                                    -Marketing Design<br /> 
                                </p>
                            </Stack>
                        
                        </Box>
                        <Box>
                            <Typography variant='h3' mb={3} align={isMobile ? 'center' : ''} className={styles.titleDecoration}>
                                Contact
                            </Typography>
                            <p align={isMobile ? 'center' : ''}>
                                Email: <a href="mailto:contact@moonizenstudios.com" className={styles.domainText}>contact@moonizenstudios.com</a>
                            </p> 
                            <p align={isMobile ? 'center' : ''}>
                                Website: <a href="https://moonizenstudios.com/" target="_blank" rel="noreferrer" className={styles.domainText}>moonizenstudios.com</a> 
                            </p>
                        </Box>
                    </Box>
                </Box>

            </Box>
            <Box className={styles.aboutButtonContainer}>
                <Box className="container">
                <Box className={styles.bottomAboutContainer}></Box>
                </Box>
            </Box>
        </>
    );
};

export default Studios;
