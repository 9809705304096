import React, {useRef, useEffect} from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Typography,
    Box,
    Stack,
    useMediaQuery,
} from "@mui/material";
import styles from './DisclaimerModal.module.css';

const DisclaimerModal = ({
    open,
    transition,
    handleClose,
   
}) => {
    
    const isMobile = useMediaQuery('(max-width:900px)');

    const descriptionElementRef = useRef(null);
    const scroll = "paper";
    
    useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }            
        }

    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={transition}
            scroll={scroll}
            fullWidth={true}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" 
                sx={{backgroundColor: '#00191b', color: 'white'}}
            >
                <Box>   
                    {"Disclaimer"}
                </Box>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <Stack direction={"row"} flexWrap={'wrap'} justifyContent={"start"} mb={1} mt={1}>
                    <Typography variant='body' sx={{fontSize: '15px', color: 'white'}}>
                        Crypto products and [non-fungible tokens or NFTs] are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions. This is an unregulated product and does not constitute a “currency,” “securities,” “custodian” or “depositories”. Information on past performance is not an indication or an endorsement of future performance.
                        Investments in unregulated digital assets are risky and should not be done without proper research. DO NOT Invest more than you’re willing to lose. 
                        The Moonizen Studios, Inc. organization holds the explicit right to utilize funds accumulated as directed by members of the organization. 
                        Moonizen Studios, Inc. organization maintains the right to perform actions related to the token contract at the discretion of members of the Moonizen Studios, Inc. organization. NFT Purchasers hold harmless Moonizen Studios, Inc. for any actions taken on behalf of the holder or the Moonizen Studios, Inc. incorporated entity and its members. Must be 18 years or older to participate in this digital asset.
                    </Typography>
                </Stack>
               
            </DialogContent>
            <DialogActions className={styles.content}>
                <Button
                    variant="contained" 
                    onClick={handleClose}
                >
                    {"Close"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DisclaimerModal;