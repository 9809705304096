import React from 'react';
import { Box, Link, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import WalletModal from '../WalletModal/WalletModal';
import './Navigation.css';
import Logo from '../../assets/images/Main-header-logo.svg';
import Logo500 from '../../assets/images/logo-p-500.png';
import Discord from '../../assets/images/discord.svg';
import Twitter from '../../assets/images/twitter.svg';
import Opensea from '../../assets/images/opensea.png';
import Treelink from '../../assets/images/treelink.png';
import Matic from '../../assets/images/matic.png';
import Studio from '../../assets/images/Studio-navbar-logo.svg'

const Navigation = () => {

    const isMobile = useMediaQuery('(max-width:991px)');
    
    const [ openConnectDlg, setOpenConnectDlg ] = React.useState(false);

    // const handleConnectWallet = () => {
    //     if( !account ) {
    //         setOpenConnectDlg(true);
    //     } else {
    //         deactivate();
    //     }
    // }

    return (
        <Box className="navbar w-nav" data-animation='default' data-collapse='medium' data-duration='400' data-easing='ease'
            data-easing2='ease' role='banner'>
            <Box className="container nav-container">
                <NavLink to='/' end className="brand w-nav-brand">
                    <img className="logo" src={Logo} loading='lazy' alt=''
                        /* srcSet={`${Logo500} 500w, ${Logo} 800w`} */
                        sizes='(max-width: 479px) 73vw, 250px'
                    />
                </NavLink>
                <nav role='navigation' className="navMenu w-nav-menu">
                    <a href='https://mint.moonizens.com/' className="navLink w-nav-link" target='_blank' rel="noreferrer">Mint</a>
                    <a href='https://genesiswhitepaper.moonizens.com/' className="navLink w-nav-link" target='_blank' rel="noreferrer">Whitepaper</a>
                    <NavLink to='/faq' className="navLink w-nav-link">FAQ</NavLink>
                    <a href='https://media.moonizens.com/' className="navLink w-nav-link" target='_blank' rel="noreferrer">Media</a>
                    <HashLink to='/#starchart' className="navLink w-nav-link">StarChart</HashLink>
                    <NavLink to='/studios' className="navLink w-nav-link">
                        <img src={Studio} loading='lazy' alt='' width="115px" height="20px"
                        />
                    </NavLink>
                    {
                        isMobile && 
                        <>
                            <Link href='https://discord.gg/moonizens' target='_blank' className="socialIconLink w-inline-block">
                                <img src={Discord} alt='discord' />
                            </Link>
                            <Link href='https://twitter.com/moonizensnft' target='_blank' className="socialIconLink w-inline-block">
                                <img src={Twitter} alt='twitter' />
                            </Link>
                            <Link href='https://opensea.io/collection/moonizen-genesis' target='_blank' className="socialIconLink w-inline-block">
                                <img src={Opensea} alt='opensea' />
                            </Link>
                            <Link href='https://linktr.ee/moonizens' target='_blank' className="socialIconLink w-inline-block">
                                <img src={Treelink} alt='treelink' />
                            </Link>
                            <Link href='https://polygonscan.com/token/0xc4e051863a646395b8503fb42dd1583398114454' target='_blank' className="socialIconLink w-inline-block">
                                <img src={Matic} alt='matic' />
                            </Link>
                        </>
                    }
                </nav>
                <nav role='navigation' className="navMenu socialIcons navSocial w-nav-menu">
                    <Link href='https://discord.gg/moonizens' target='_blank' className="socialIconLink w-inline-block">
                        <img src={Discord} alt='discord' />
                    </Link>
                    <Link href='https://twitter.com/moonizensnft' target='_blank' className="socialIconLink w-inline-block">
                        <img src={Twitter} alt='twitter'/>
                    </Link>
                    <Link href='https://opensea.io/collection/moonizen-genesis' target='_blank' className="socialIconLink w-inline-block">
                        <img src={Opensea} alt='opensea' />
                    </Link>
                    <Link href='https://linktr.ee/moonizens' target='_blank' className="socialIconLink w-inline-block">
                        <img src={Treelink} alt='treelink' />
                    </Link>
                    <Link href='https://polygonscan.com/token/0xc4e051863a646395b8503fb42dd1583398114454' target='_blank' className="socialIconLink w-inline-block">
                        <img src={Matic} alt='matic' width={'70px'}/>
                    </Link>
                </nav>
                <Box className="menuButton w-nav-button">
                    <Box className="icon w-icon-nav-menu" />
                </Box>
            </Box>
            <WalletModal show={openConnectDlg} onClickAway={() => setOpenConnectDlg(false)} />
        </Box>
    );
};

export default Navigation;