import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import Swal from 'sweetalert2';
import { injected, walletconnect } from '../../config/connectors';
import {
    Dialog, 
    DialogContent, 
    Slide, 
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
   
} from "@mui/material";
import MetamaskIcon from '../../assets/images/Metamask-icon.png';
import WalletConnectIcon from '../../assets/images//WalletConnect-icon.png';
import { styled } from '@mui/system';
import './WalletModal.css';


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});


const WalletIcon = styled('img')({
    width: '50px'
});

const WalletModal = ({ show, onClickAway }) => {

    const { activate, account } = useWeb3React();

    const activateAccount = async () => {
        return new Promise(resolve => {
            resolve(activate(walletconnect))
        })
    }

    const activateInjected = async () => {
        return new Promise(resolve => {
            resolve(activate(injected))
        })
    }

    const connectWalletconnect = async () => {
        await activateAccount()
    }

    const connectMetamask = async () => {
        if (window.ethereum) {

            if (window.ethereum.networkVersion !== "31337" && window.ethereum.networkVersion !== "137" && window.ethereum.networkVersion !== "80001") {
                onClickAway();
                Swal.fire({
                    icon: 'warning',
                    title: 'You are on the wrong network.',
                    text: `Please connect to Polygon network!`
                })
            } else {
                await activateInjected();
            }
        } else {
            onClickAway();
            Swal.fire({
                icon: 'warning',
                title: 'No Wallet Detected',
                text: `Please Please install Metamask!`
            })
            return false;
        }
    }

    useEffect(() => {
        onClickAway();
    }, [account]);

    return (

        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClickAway}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent className='modal-container'>
                <List sx={{pt: 0}}>
                    <ListItem button onClick={connectMetamask}>
                        <ListItemAvatar>
                            <WalletIcon src={MetamaskIcon} />
                        </ListItemAvatar>
                        <ListItemText 
                            primary={"MetaMask"} 
                            sx={{ml: 2, color: 'white'}}
                        />
                    </ListItem>
                </List>
                
                <List sx={{pt: 0}}>
                    <ListItem button onClick={connectWalletconnect}>
                        <ListItemAvatar>
                            <WalletIcon src={WalletConnectIcon} />
                        </ListItemAvatar>
                        <ListItemText 
                            primary={"WalletConnect"}
                            sx={{ml: 1, color: 'white'}}
                        />
                    </ListItem>
                    
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default WalletModal;